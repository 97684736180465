import React, { useState } from "react";
import styled from "styled-components";
import Heading from "../../../../../components/Heading/Heading";
import Text from "../../../../../components/Text/Text";
import Button from "../../../../../components/Button/Button";
import Input from "../../../../../components/Input/Input";
import MembershipType from "./MembershipType";

const Container = styled.div`
  width: 100%;
`;
const Margin = styled.div`
  height: 0.25rem;
`;





const Membership = ({hide,onNext}) => {

  const [membership,setMembership] = useState()

  const handleNext = ()=>{
    console.log("Saasasa",membership)
    const isValid = membership == 1 || membership == 2
    if(!isValid){
      alert("please select membership")
      return
    }
    onNext({membership})
  }
  return (
    <div style={{
      display:hide?"none":"block"
  }}>
      <Container
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Heading color="#45586B" size="30px" Text="Select Your Membership" />
        <Margin />
        <Text
          color="#8692A6"
          size="18px"
          //   lh="28px"
          fw="Regular"
          //   ls="0.05em"
          Text="Please Select Your Membership"
        />


        <div style={{marginTop:20}}>

          <MembershipType onMembershipChanged={(type)=>{
              setMembership(type)
          }}/>
         
        </div>
      </Container>
     
      <Margin style={{ height: "2rem" }} />
      <Margin style={{ height: "2rem" }} />

      <div style={{ textAlign: "center" }}>
        
        <Button Text="Next" color="#141E30"  onClick={()=>{
          handleNext()
        }} />
      </div>
    </div>
  );
};

export default Membership;
