import './App.css';
import HomePage from './Pages/Home';
import EmailCard from './Pages/Cards/Email/EmailCard';
import Otp from './Pages/Cards/OTP/Otp';
import Verification from './Pages/Cards/Verification/Verification';
import SideBar from './components/SideBar/SideBar';
import Profile from './Pages/Profile/Profile';
import Login from './Pages/Login';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
 
function App() {
  return (
    <>
    <Login/>
    
      {/* <EmailCard />
      <Otp />
      <Verification /> */}
      {/* <SideBar /> */}
      {/* <Profile /> */}
    </>
  );
}

export default App;
