import React, { useRef, useState } from "react";
import styled from "styled-components";
import Heading from "../../../../components/Heading/Heading";
import Text from "../../../../components/Text/Text";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import { Steps } from "primereact/steps";
import { Toast } from "primereact/toast";
import "./style.css";
import Login from "./Login";
import Membership from "./Membership";
import Details from "./Details";
import Document from "./Document";

const StyledRightSide = styled.div`
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 30px 0 0 30px;
  padding: 4rem 0 2.5rem 0;
`;
const Container = styled.div`
  width: 80%;
`;
const Margin = styled.div`
  height: 0.25rem;
`;

const RightSide = () => {
  const [membershipType, setMembershipType] = useState();
  const [referralId, setReferralId] = useState();
  const [password, setPassword] = useState();
  const [info, setInfo] = useState();

  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useRef();

  const handleStepClick = (step) => {
    toast.current.show({
      severity: "info",
      summary: "First Step",
      detail: step,
    });
  };
  const interactiveItems = [
    {
      label: "Login",
      
    },
    {
      label: "Membership",
      
    },
    {
      label: "Details",
      
    },
    {
      label: "Documents",
      
    },
  ];
  return (
    <StyledRightSide>
      <div className="steps" style={{ width: "100%" }}>
        <Toast ref={toast}></Toast>
        <Steps
          model={interactiveItems}
          activeIndex={activeIndex}
          onSelect={(e) => setActiveIndex(e.index)}
          readOnly={true}
        />
      </div>

      <div
        style={{
          alignContent: "center",
          paddingLeft: 25,
          paddingRight: 25,
          marginTop: 25,
          marginBottom: 25,
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Login
          hide={activeIndex != 0}
          onNext={({ referralId, password }) => {
            setReferralId(referralId);
            setPassword(password);
            setActiveIndex(1);
          }}
        />
        <Membership
          hide={activeIndex != 1}
          onNext={({ membership }) => {
            setMembershipType(membership);
            setActiveIndex(2);
          }}
        />

        <Details
          hide={activeIndex != 2}
          onNext={(info) => {
            setInfo(info);
            setActiveIndex(3);
          }}
        />

        <Document
          hide={activeIndex != 3}
          onNext={(info) => {
            setInfo(info);
            setActiveIndex(3);
          }}
        />
      </div>
    </StyledRightSide>
  );
};

export default RightSide;
