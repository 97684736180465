import React, { useState } from "react";
import styled from "styled-components";
import Heading from "../../../../../components/Heading/Heading";
import Text from "../../../../../components/Text/Text";
import Button from "../../../../../components/Button/Button";
import Input from "../../../../../components/Input/Input";
import { InputText } from "primereact/inputtext";
import DocumentDropDown from "./DocumentDropdown";

const Container = styled.div`
  width: 100%;
`;
const Margin = styled.div`
  height: 0.25rem;
`;

const Document = ({ hide, onNext }) => {
  const [name, setName] = useState();
  const [fatherName, setFathername] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [email, setEmail] = useState();
  const [alternateNumber, setAlternateNumber] = useState();

  const [dob, setDob] = useState();
  const [dematAccount, setDematAccount] = useState();

  const handleNext = () => {
    const isValid =
      name && fatherName && mobileNumber && email && alternateNumber && dob;

    // if (!isValid) {
    //   alert("Please Fill The Form Correctly");
    //   return;
    // }

    onNext({
      name,
      fatherName,
      mobileNumber,
      email,
      alternateNumber,
      dob,
      dematAccount,
    });
  };


  const identityProofs =  [
    { name: 'Aadhar Card', code: 'AC' },
    { name: 'Driving Licence', code: 'DL' },
    { name: 'Passport', code: 'PS' },
    { name: 'Voter Id', code: 'VT' },
];

const addressProofs =  [
  { name: 'Aadhar Card', code: 'AC' },
  { name: 'Driving Licence', code: 'DL' },
  { name: 'Passport', code: 'PS' },
  { name: 'Voter Id', code: 'VT' },
];

  return (
    <div
      style={{
        display: hide ? "none" : "block",
      }}
    >
      <Container
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Heading color="#45586B" size="30px" Text="Upload Documents" />
        <Margin />
        <Text
          color="#8692A6"
          size="18px"
          //   lh="28px"
          fw="Regular"
          //   ls="0.05em"
          Text="Please Provide All The Documents"
        />
      </Container>
     

      <div style={{marginTop:12}}>
     <Text
          color="#8692A6"
          size="13px"
          fw="Bold"
          Text="Identity Proof"
        />

        <DocumentDropDown items={identityProofs}/>
     </div>
   
     <div style={{marginTop:12}}>
     <Text
          color="#8692A6"
          size="13px"
          fw="Bold"
          Text="Address Proof"
        />

        <DocumentDropDown items={addressProofs}/>
     </div>
   
      <Margin style={{ height: "2rem" }} />
      <Margin style={{ height: "2rem" }} />

      <div style={{ textAlign: "center" }}>
        <Button
          Text="Next"
          color="#141E30"
          onClick={() => {
            handleNext();
          }}
        />
      </div>
    </div>
  );
};

export default Document;
