import React, { useState } from "react";
import styled from "styled-components";
import Heading from "../../../../../components/Heading/Heading";
import Text from "../../../../../components/Text/Text";
import Button from "../../../../../components/Button/Button";
import Input from "../../../../../components/Input/Input";
import { InputText } from "primereact/inputtext";

const Container = styled.div`
  width: 100%;
`;
const Margin = styled.div`
  height: 0.25rem;
`;

const Details = ({ hide, onNext }) => {
  const [name, setName] = useState();
  const [fatherName, setFathername] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [email, setEmail] = useState();
  const [alternateNumber, setAlternateNumber] = useState();

  const [dob, setDob] = useState();
  const [dematAccount, setDematAccount] = useState();

  const handleNext = () => {
    const isValid =
      name && fatherName && mobileNumber && email && alternateNumber && dob;

    // if (!isValid) {
    //   alert("Please Fill The Form Correctly");
    //   return;
    // }

    onNext({
      name,
      fatherName,
      mobileNumber,
      email,
      alternateNumber,
      dob,
      dematAccount,
    });
  };
  return (
    <div
      style={{
        display: hide ? "none" : "block",
      }}
    >
      <Container
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Heading color="#45586B" size="30px" Text="Basic Details" />
        <Margin />
        <Text
          color="#8692A6"
          size="18px"
          //   lh="28px"
          fw="Regular"
          //   ls="0.05em"
          Text="Please Provide All The Details in following form"
        />
      </Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <Input
          value={name}
          onTextChange={(t) => {
            setName(t);
          }}
          placeholder="Full Name"
        />

        <Input
          value={fatherName}
          onTextChange={(t) => {
            setFathername(t);
          }}
          placeholder="Fathers/Husband's Name"
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <Input
          value={mobileNumber}
          onTextChange={(t) => {
            setMobileNumber(t);
          }}
          placeholder="Mobile No."
        />

        <Input
          value={alternateNumber}
          onTextChange={(t) => {
            setAlternateNumber(t);
          }}
          placeholder="Alternate Mobile No."
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <Input
          value={email}
          onTextChange={(t) => {
            setEmail(t);
          }}
          placeholder="Email Address"
        />

        <Input
          value={dob}
          // type="date"
          onTextChange={(t) => {
            setDob(t);
          }}
          placeholder="DOB (DD/MM/YYYY)"
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <div />

        <Input
          value={dematAccount}
          // type="date"
          onTextChange={(t) => {
            setDematAccount(t);
          }}
          placeholder="Demat A/C No"
        />
      </div>

      <Margin style={{ height: "2rem" }} />
      <Margin style={{ height: "2rem" }} />

      <div style={{ textAlign: "center" }}>
        <Button
          Text="Next"
          color="#141E30"
          onClick={() => {
            handleNext();
          }}
        />
      </div>
    </div>
  );
};

export default Details;
