import { useEffect, useState } from "react";
import "./style.css";
const MembershipType = ({membershipType,onMembershipChanged}) => {
  const [checked, setChecked] = useState(membershipType);

  useEffect(()=>{
    if(checked == 2 || checked == 1)
        onMembershipChanged(checked)
  },[checked])
  return (
    <div className="container">
      <div className="plans">
        <label className="plan basic-plan" htmlFor="basic">
          <input
            defaultChecked=""
            onChange={(e) => {
              setChecked(1);
            }}
            checked={checked == 1}
            type="radio"
            name="plan"
            id="basic"
          />
          <div className="plan-content">
            <img
              loading="lazy"
              src="https://raw.githubusercontent.com/ismailvtl/ismailvtl.github.io/master/images/life-saver-img.svg"
              alt=""
            />
            <div className="plan-details">
              <span>Basic</span>
              <p>Pay Rs.1000 and get membership of Ramaera Ltd.</p>
            </div>
          </div>
        </label>
        <label className="plan complete-plan" htmlFor="complete">
          <input
            type="radio"
            checked={checked == 2}
            onChange={(e) => {
              setChecked(2);
            }}
            id="complete"
            name="plan"
          />
          <div className="plan-content">
            <img
              loading="lazy"
              src="https://raw.githubusercontent.com/ismailvtl/ismailvtl.github.io/master/images/potted-plant-img.svg"
              alt=""
            />
            <div className="plan-details">
              <span>Advance</span>
              <p>Pay Rs. 1 Lakh or Rs 1.25 Lakhs and get subscription of Ramaera Ltd.</p>
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default MembershipType;
