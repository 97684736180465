import React, { useState } from "react";
import styled from "styled-components";
import Line from "../Line/Line";
const Div = styled.div`
margin: 3rem 0 0 0;
`;
const StyledInput = styled.input`
color: #082F37;
font-family: Poppins;
font-size: 14px;
width:100%;
font-weight: 500;
line-height: 21px;
letter-spacing: 0em;
text-align: left;  
border: none; 
background-color: transparent;

&:placeholder{
    color: #A1A1A1;
    Font style: Medium;
    Font size: 14px;
    Line height: 21px;
    Line height: 100%;
    
}

&:focus, &:focus{
    outline: none;
    
}
`;


const Input = (props) => {
 



  return (
    <Div style={{width:"100%",marginLeft:10,marginRight:10}}>
      <StyledInput
        {...props}

        onChange={(e) => {
            if(props.onTextChange)
                props.onTextChange(e.target.value)
        }}
        placeholder={props.placeholder ? props.placeholder : "placeholder"}
      />
      <div style={{ height: "0.5rem" }} />
      <Line />
    </Div>
  );
};

export default Input;
