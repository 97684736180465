import React, { useState } from "react";
import styled from "styled-components";
import Heading from "../../../../../components/Heading/Heading";
import Text from "../../../../../components/Text/Text";
import Button from "../../../../../components/Button/Button";
import Input from "../../../../../components/Input/Input";

const Container = styled.div`
  width: 100%;
`;
const Margin = styled.div`
  height: 0.25rem;
`;

const Login = ({hide,onNext}) => {
  const [referralId, setReferralId] = useState("");
  const [password, setPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);

  const handleNext = ()=>{
    onNext({password,referralId})
  }
  return (
    <div style={{
        display:hide?"none":"block"
    }}>
      <Container
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Heading color="#45586B" size="30px" Text="Login To KYC" />
        <Margin />
        <Text
          color="#8692A6"
          size="18px"
          //   lh="28px"
          fw="Regular"
          //   ls="0.05em"
          Text="For the purpose of industry regulation, your details are required."
        />
      </Container>
      <Input
        value={referralId}
        onTextChange={(t) => {
          setReferralId(t);
        }}
        placeholder="Planetway Referral ID"
      />
      <Input
        value={password}
        type="password"
        onTextChange={(t) => {
          setPassword(t);
        }}
        placeholder="Password"
      />
      <Margin style={{ height: "2rem" }} />
      <Margin style={{ height: "2rem" }} />

      <div style={{ textAlign: "center" }}>
        <Button Text="Next" color="#141E30" loading={loginLoading}  onClick={()=>{
          handleNext()
        }}/>
         </div>
    </div>
  );
};

export default Login;
